import SpeechRecognition from "react-speech-recognition";

export class AudioRecorderSpeechText {
  name = "AudioRecorderSpeechText";
  private recorderHook;
  private handleSend;

  constructor(recorderHook, handleSend) {
    this.recorderHook = recorderHook;
    this.handleSend = handleSend;
  }

  async startRecording() {
    try {
      if (!this.recorderHook.isRecording) {
        this.recorderHook.resetTranscript();

        SpeechRecognition.startListening({
          language: "pt-Br",
          continuous: true,
        });
      }
    } catch (error: any) {
      console.log(error);
      this.resetRecording();
    }
  }

  async sendResult(result) {
    try {
      this.setResult(null);
      await this.callbackSendResult(result);
    } catch (error) {
      console.log(error);
      this.resetRecording();
    }
  }

  private callbackSendResult = (result): Promise<any> => {
    const self = this;

    return new Promise((resolve, reject) => {
      self.handleSend(result, (response) => {
        !!response ? resolve(response) : reject(response);
      });
    });
  };

  async stopRecording() {
    try {
      console.log("stopRecording", this.recorderHook);

      await SpeechRecognition.stopListening();
      this.setIsAfterOnStop(true);
    } catch (error) {
      console.log(error);
      this.resetRecording();
    }
  }

  async cancelRecording() {
    await SpeechRecognition.abortListening();
    this.resetRecording();
  }

  protected resetRecording() {
    this.setResult(null);
    this.setIsAfterOnStop(false);
    this.recorderHook.resetTranscript();
  }

  protected setIsAfterOnStop = (value) => {
    this.recorderHook.setIsAfterOnStop(value);
    return this;
  };

  protected setResult = (value) => {
    this.recorderHook.setResult(value);
    return this;
  };
}
