import React, { useCallback } from "react";
import { MdClose, MdMic, MdDone } from "react-icons/md";
import { isIOS, isMobile } from "react-device-detect";

const SendButtons = (props) => {
  const { isRecording, startRecording, stopRecording, cancelRecording, timer } =
    props;

  const renderMicOpt = useCallback(() => {
    if (isRecording) {
      return (
        <>
          <button className="send" onClick={() => cancelRecording()}>
            <div className="circle audio-cancel">
              <MdClose />
            </div>
          </button>
          <p>
            <span>{timer}</span>
          </p>
          <button className="send" onClick={() => stopRecording()}>
            <div className="circle audio-send">
              <MdDone />
            </div>
          </button>
        </>
      );
    } else {
      return (
        <button className="send" onClick={() => startRecording()}>
          <div className="circle">
            <MdMic />
          </div>
        </button>
      );
    }
  }, [cancelRecording, isRecording, startRecording, stopRecording, timer]);

  const renderBody = useCallback(() => {
    return (isMobile || window.ReactNativeWebView) && !isIOS ? (
      <button
        className="send"
        onTouchStart={() => startRecording()}
        // onTouchCancel={() => cancelRecording()}
        onTouchEnd={() => stopRecording()}
      >
        <div className={`circle ${isRecording ? "red-circle" : ""}`}>
          <MdMic />
        </div>
      </button>
    ) : (
      <div className="container-audio">{renderMicOpt()}</div>
    );
  }, [isRecording, renderMicOpt, startRecording, stopRecording]);

  return renderBody();
};

export default React.memo(SendButtons);
