import { toast } from "react-toastify";
import { AbstractAudioRecorderMedia } from "./AbstractAudioRecorderMedia";

export class AudioRecorderGetUserMedia extends AbstractAudioRecorderMedia {
  name = "AudioRecorderGetUserMedia";

  async startRecording() {
    try {
      this.resetRecording();
      const self = this;

      if (
        !self.recorderHook.isRecording &&
        self.recorder?.state !== "recording"
      ) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        self.localStream = stream;
        self.recorder = new MediaRecorder(stream);
        self.recorder.addEventListener("dataavailable", self.onDataAvailable);
        self.recorder.addEventListener("stop", self.onStop);
        self.recorder.addEventListener("start", self.onStart);

        self.recorder.start();
      }
    } catch (error: any) {
      console.log(error);
      this.resetRecording();

      if (error.message === "getUserMedia is not implemented in this browser") {
        this.browserHandle.setNotRecordingCompatible();
        toast.error("Sem suporte para gravação de audio.");
      }

      if (error.message === "Permission denied") {
        toast.error("Sem permissão para gravar.");
      }
    }
  }
}
